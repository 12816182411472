<template>
  <div class="container-md col-lg-10 col-md-10 review-activity">
    <div class="d-flex">
      <h3 class="text-primary w-100">Activity Review</h3>
      <CButton
      class="justify-content-end text-nowrap"
        color="light"
        shape="pill"
        @click="backToList"
        >Back to list</CButton
      >
    </div>
    <div class="mt-3 mb-3">
      <ReviewActivity
        v-if="isPendingActivity"
        :activityDetails="selectedActivity"
        @changeActivityStatus="changeActivityStatus"
      />
      <h4 v-else class="text-muted p-4 text-center">
        No Pending Activity Available for Review
      </h4>
    </div>
  </div>
</template>
<script>
import ReviewActivity from "@/containers/ActivityList/JobAuthoriser/ReviewActivity";
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper.js";
export default {
  components: {
    ReviewActivity,
  },
  computed: {
    ...mapGetters(["selectedActivity"]),
    isPendingActivity() {
      return (
        isObject(this.selectedActivity) &&
        !isEmptyObjectCheck(this.selectedActivity) &&
        this.selectedActivity.completed_date === null &&
        this.selectedActivity.completed_by === null &&
        this.selectedActivity.candidate_uid === null &&
        this.selectedActivity.job_id != null
      );
    },
  },
  methods: {
    ...mapActions(["fetchSelectedActivity", "approveAuthoriser"]),
    changeActivityStatus(payload) {
      this.approveAuthoriser(payload);
    },
    backToList(){
      this.$router.push({path:`/list-activity`})
    }
  },
  mounted() {
    const {
      params: { id },
    } = this.$route;
    let activity_id = id ? parseInt(id) : null;
    this.fetchSelectedActivity(activity_id);
  },
};
</script>