<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3">
          <h5 class="text-primary">
            {{ activityDetails.job.job_title }}
          </h5>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary font-weight-bold">
              <span>{{ activityDetails.expiry_date | formatDate }}</span>
              <span class="d-block"
                ><span class="font-weight-lighter pr-1">Days Left:</span
                >{{ activityDetails.expiry_date | remainingDays }}
              </span>
            </span>
          </div>
        </div>
        <div>
          <div class="d-flex pb-2">
            <span class="text-muted h6">Comments : </span>
            <span class="h6 pl-1">{{ activityDetails.comments || "--" }}</span>
            <CButton
              type="button"
              color="primary"
              class="ml-auto mr-0"
              @click="navToJobDetails(activityDetails.job_id)"
              >View Job</CButton
            >
          </div>
          <div class="pt-2">
            <ValidationObserver ref="reviewCandidate" v-slot="{ handleSubmit }">
              <form
                id="reviewCandidate"
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <CRow>
                  <CCol lg="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12 col-md-12 required"
                        >Comment</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextareaInput
                            name="comments"
                            :value="review.comments"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <div class="text-center">
                  <CButton
                    type="button"
                    color="primary"
                    class="action-btn mx-1 p-2"
                    variant="outline"
                    shape="square"
                    @click="navToActivityList"
                    >Cancel</CButton
                  >
                  <CButton
                    type="button"
                    color="primary"
                    class="action-btn mx-1 p-2"
                    shape="square"
                    @click="onSubmit"
                    >Approve</CButton
                  >
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    TextareaInput,
  },
  data() {
    return {
      review: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["showToast", "fetchActivity"]),
    handleInput(name, value) {
      Vue.set(this.review, name, value);
      Vue.set(this.payload, name, value);
    },
    navToActivityList() {
      this.$router.push({ path: `/list-activity` });
    },
    navToJobDetails(job_id) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      let routeData = this.$router.resolve({
        path: `/job-list-preview/${job_id}`,
      });
      window.open(routeData.href, "_blank");
    },
    async onSubmit() {
      const isValid = await this.$refs.reviewCandidate.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        job_id: this.activityDetails?.job_id,
        comment: this.review?.comments,
        customer_id: this.activityDetails?.customer_uid,
        action_detail_id: this.activityDetails?.action_detail_id,
        organisation_uid: this.activityDetails?.organisation_id,
      };
      this.$emit("changeActivityStatus", finalPayload);
    },
  },
  filters: {
    formatDate(data) {
      if (data) return m().utc(data).format("Do MMM YYYY");
      return "--";
    },
    remainingDays(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m();
        return alertDate.diff(todaysdate, "days");
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  width: 110px;
}
</style>